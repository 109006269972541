<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" top="5vh">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="120px"
      class="demo-form-inline"
    >
      <el-form-item label="校区名称" prop="merchantName">
        <el-input v-model="formInfo.merchantName"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="formInfo.merchantStatus" :label="'正常'">正常</el-radio>
        <el-radio v-model="formInfo.merchantStatus" :label="'禁用'">禁用</el-radio>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
  },
  data() {
    return {
      dialogVisible: false,
      formInfo: {
        merchantName: "",
        merchantStatus: "",
      },
      rules: {
        merchantName: [
          { required: true, message: "请输入校区名称", trigger: "blur" },
        ],
        merchantStatus: [{ required: true, message: "请输入状态", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.$emit("submit", {...this.formInfo,loginName:this.formInfo.tel});
          this.$emit("submit", {...this.formInfo});

        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        merchantName:'',
        merchantStatus: "正常",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}
@import "~@/style/dialog.scss";

</style>
