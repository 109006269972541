<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" top="5vh">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="120px"
      class="demo-form-inline"
    >
      <el-form-item label="商户名称" prop="merchantName">
        <el-input v-model="formInfo.merchantName"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="formInfo.status" :label="'正常'">正常</el-radio>
        <!-- <el-radio v-model="formInfo.status" label="2">备选项</el-radio> -->
        <el-radio v-model="formInfo.status" :label="'禁用'">禁用</el-radio>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    item: { type: Object, default: () => {} },
  },
  watch: {
    item: {
      handler(val) {
        this.formInfo = val;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    const validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      formInfo: {
        merchantName: "",
        name: "",
        telNumber: "",
        password: "",
        status: "正常"
      },
      rules: {
        merchantName:[{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        telNumber: [
          { required: false, message: "请输入手机号", trigger: "blur" },
          // { required: false, validator: validatorPhone, trigger: "blur" },
        ],
        password: [{ required: false, message: "请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）", trigger: "blur" }],
        status: [{ required: false, message: "请输入状态", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", {...this.formInfo});
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}
@import "~@/style/dialog.scss";

</style>